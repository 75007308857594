<template>
  <v-container
    fluid
    class="d-flex align-start pt-16 container--fluid background"
  >
    <v-row
      class="d-flex align-center"
    >
      <v-col
        cols="12"
        class="d-flex justify-center mt-16"
      >
        <v-card
          width="60vw"
        >
          <v-sheet
            width="90%"
            style="margin-right: 5%; margin-left: 5%"
            class="text-h3 text-center"
          >
            <div
              v-if="loginRoute"
              class="d-flex justify-center mt-12 mx-12"
            >
              <span class="d-flex justify-center flex-wrap"> The path: &nbsp; <span class="font-weight-medium">{{ `/login/${loginRoute} ` }}</span> &nbsp; could not be found. <span>Please double check everything is spelled correctly. </span></span>
            </div>
            <div
              v-else
              class="d-flex justify-center mt-12 mx-12"
            >
              <span class="d-flex justify-center flex-wrap"> <span>Your session has expired. &nbsp;</span> Please return to your company's login page to access Lumii. </span>
            </div>
            <div class="mx-12 mt-6 mb-12">
              <span class="d-flex justify-center flex-wrap"> If you need additional assistance, please reach out to us at:  &nbsp;
                <span><v-icon color="primary">mdi-email</v-icon> <a href="mailto:support@afs-controls.com">  support@afs-controls.com </a></span>
              </span>
            </div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'FourOhFourLogin',
    data: () => ({
      loginRoute: '',
    }),
    created () {
      this.loginRoute = this.$route.query.loginRoute
    },
  }
</script>

<style>
.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 110vw;
  overflow: hidden;
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.75), rgba(51, 9, 1, 0.73)),
  url('../assets/register.jpg');
  background-size: cover;
}
</style>
